/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { MetadataButtons } from './MetadataButtons'
import { AppStore } from 'utils'
import { IPostVariant } from 'types/PostInterface'
import { findVisibleMetadata } from 'utils/PostUtils/ShortUrl'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
  firstComment?: boolean
}

export const Metadata = ({ post, firstComment }: Props) => {
  /* ----------------- VARIABLES  */
  const firstMetadata = findVisibleMetadata(post, firstComment)
  const visibleLink = firstMetadata ? firstMetadata[0] : undefined

  const { ogDescription, ogUrl, ogImg, ogTitle } = visibleLink.metaData

  return (
    <MetadataContainer>
       <MetadataButtons post={post} visibleLink={visibleLink} />

      <MetadataImage
        onClick={() => {
          window.open(ogUrl, '_blank')
        }}
        src={ogImg ?? ''}
      />

      <MetadataContent>
        <MedataTitle>{ogTitle}</MedataTitle>
        <Divider />
        <MedataDescription>{ogDescription}</MedataDescription>
      </MetadataContent>
    </MetadataContainer>
  )
}

const MetadataContainer = styled(Box)`
  position: relative;
  height: 160px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border-radius: 14px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  background: ${() => AppStore.theme.o.surface};
`

const MetadataImage = styled.img`
  margin-top: 8px;
  width: 56px;
  height: 56px;
  border-radius: 12px;
  cursor: pointer;
  object-fit: cover;
`

const MetadataContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 334px;
`

const MedataTitle = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  border-radius: 0px;
  max-width: 75%;
  max-height: 44px;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding: 8px;
`

const Divider = styled(Box)`
  width: calc(100%-8px);
  height: 0.5px;
  background-color: ${() => AppStore.theme.o.grey};
  margin: 4px 0px 4px 8px;
`

const MedataDescription = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px;
  max-height: 65px;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`
