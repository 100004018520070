import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import Utils from 'api/Utils'
import { Box, Button, FolderModal, Icons, Input, Text } from 'components'
import EditorButton from 'components/CreatePost/MediaComponents/EditorButton'
import { Component } from 'react'
import { AppStore, T, showInfo } from 'utils'
import {
  OWNER_STORAGE_LIMITS_AVAILABLE,
  OWNER_STORAGE_LIMITS_INFINITE,
  OWNER_STORAGE_LIMITS_USED,
} from 'utils/Constants/LimitUserConstants'
import { TRACKEREVENTS, getRefUrlProperties } from 'utils/eventsTracker'
import GigaCounter from './GigaCounter'

interface Props {
  onFileChange: (e: any) => void
  inputFileRef: any
  customInputOnChange: (e: any) => void
  refreshMedia: () => void
  path: string
  isModal?: boolean
  background: any
  openEditor: () => void
}

interface State {
  searchText: string
  selectedMedias: any
}

export default class MediaHeader extends Component<Props, State> {
  state: State = {
    searchText: '',
    selectedMedias: [],
  }
  currentURL = window.location.href
  sanitizedURL = this.currentURL.replace(/https?:\/\/[^/]+/, '')

  handleMediaUpload = async () => {
    try {
      const check = await AppStore.checkPermission('upload_media')

      if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.uploadMedia}`)

      if (!OWNER_STORAGE_LIMITS_INFINITE) {
        if (OWNER_STORAGE_LIMITS_USED >= OWNER_STORAGE_LIMITS_AVAILABLE) {
          return showInfo(T.limitUser.max_reached_storage)
        }
      }

      if (check) {
        Tracker.trackEvent(TRACKEREVENTS.UPLOAD_FILE_INITIATED, getRefUrlProperties(this.sanitizedURL))
        this.props.inputFileRef?.current?.click()
      }
    } catch (e) {
      console.error(e)
    }
  }

  handleCreateFolder = async (refreshMedia, path) => {
    try {
      const check = await AppStore.checkPermission('manage_media')
      if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.manageMedia}`)

      Tracker.trackEvent(TRACKEREVENTS.CREATE_FOLDER_INITIATED, {})
      AppStore.openModal({
        id: 'folder-modal',
        body: <FolderModal onConfirm={refreshMedia} path={path} />,
      })
    } catch (e) {
      console.error(e)
    }
  }

  handleOpenEditor = async () => {
    try {
      const check = await AppStore.checkPermission('media_editor')
      if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.useEditor}`)

      if (check) {
        this.setState({ selectedMedias: [] }, () => this.props.openEditor())
        Utils.updateUserEditorCount()
      }
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { onFileChange, inputFileRef, customInputOnChange, path, refreshMedia, isModal } = this.props

    return (
      <Box row pt={40} mh={60}>
        {/* <CustomInput
          placeholder={T.postPage.search}
          margin={'0 0 0 18px'}
          prefix={<Icons.search fill={AppStore.theme.o.black} />}
          onChange={(s: string) => this.setState({ searchText: s })}
          onKeyPress={(e) => {
            const keyCode = e.code || e.key
            if (keyCode == 'Enter') {
              customInputOnChange(this.state.searchText)
            }
          }}
        /> */}

        {!isModal && <GigaCounter />}

        <Box row center ml={'auto'}>
          <Button
            id="upload-media-button"
            ml={'auto'}
            variant="neutral"
            onClick={this.handleMediaUpload}
            style={{ maxHeight: 40 }}
          >
            <Icons.uploadFile fill={AppStore.theme.o.black} />

            <Text>{T.mediaPage.loadFile}</Text>
          </Button>

          <input
            multiple
            style={{ display: 'none' }}
            accept="video/*,image/*"
            onChange={onFileChange}
            ref={inputFileRef}
            type="file"
          />

          {/* <Button
            id="create-folder-button"
            ml={24}
            variant="neutral"
            onClick={() => this.handleCreateFolder(refreshMedia, path)}
            style={{ maxHeight: 40 }}
          >
            <Icons.createFolderImgSection fill={AppStore.theme.o.black} style={{ marginRight: 8 }} />

            <Text>{T.mediaPage.createFolder}</Text>
          </Button> */}

          {/* <EditorButton isModal={isModal} onSave={refreshMedia} /> */}
        </Box>

        {/* {!isModal && (
          <Button
            id="open-template-media"
            ml={25}
            ph={48}
            size="small"
            variant="neutral"
            style={{
              backgroundImage: `url(${background})`,
              objectFit: 'scale-down',
              backgroundSize: '110%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            onClick={this.handleOpenEditor}
            hideBoxShadowOnHover
            preventLoading
          >
            <Icons.imgFolderMark style={{ marginRight: 4, width: 38, height: 38 }} />
            <Text style={{ color: 'white', marginRight: 8 }} weight={700}>
              {T.mediaPage.openEditor}
            </Text>
          </Button>
        )} */}
      </Box>
    )
  }
}

const CustomInput = styled(Input)`
  border-radius: 14px;
  width: 400px;
`
