/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import ShortUrl from 'api/ShortUrl'
import { Box, Button, Input } from 'components'
import { useEffect, useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IUrl, IUtm } from 'types/PostInterface'
import { AppStore, T, getActivePost } from 'utils'
import { getActiveDescription, getActiveText, updateActive, updatePost } from 'utils/PostUtils/Post'
import { newUtmUrl } from 'utils/PostUtils/ShortUrl'
/* ------------------- INTERFACES  */
interface Props {
  post: any
  visibleLink: IUrl
  firstComment?: boolean
  isDescription?: boolean
}

export const UtmModal = ({ post, visibleLink, firstComment, isDescription }: Props) => {
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as any,
  }))
  /* ------------------- STATES  */
  const [utmValues, setUtmValues] = useState({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  })

  /* ------------------- METHODS  */
  const onUtmValueChanged = (value: any) => {
    // save in local storage the utm values
    localStorage.setItem('utmValues', JSON.stringify(value))
  }

  const elaborate = async (currentText, newUtm) => {
    const { activeAccountId } = useCreatePostStore.getState()
    const socialAccount = AppStore.socialAccounts.find((account) => account._id === activeAccountId)

    // verifico il link e lo sostituisco con quello nuovo
    const utmUrl = newUtmUrl(visibleLink, newUtm)

    // recupero dei dati del nuovo link
    let {shortUrl} = await ShortUrl.getShortLinkAndMetadata(utmUrl, 'shorten', undefined)

    if (socialAccount?.customShorturlDomain) {
      shortUrl = shortUrl.replace('aste.rs', socialAccount?.customShorturlDomain)
    }

    const urlResult = shortUrl
    //replace the old url with the new one
    const newText = currentText?.replaceAll(
      visibleLink.shortened ? visibleLink.shortUrl : visibleLink.originalUrl,
      visibleLink.shortened ? urlResult : utmUrl
    )

    return {
      newText,
      urlResult,
      utmUrl,
    }
  }

  const elaborateUrls = async (urls, urlResult, utmUrl, newUtm) => {
    return (
      urls?.map((url) => {
        if (url.id === visibleLink.id) {
          const config = {
            originalUrl: utmUrl,
            shortUrl: urlResult,
          }
          return { ...url, utmConfig: newUtm, ...config }
        }
        return url
      }) ?? []
    )
  }

  const updateUrls = async (urls, newUtm) => {
    // ottiene i nuovi dati
    const currentText = !isDescription ? getActiveText() : getActiveDescription()

    const { utmUrl, urlResult, newText } = await elaborate(currentText, newUtm)

    console.log(`UpdateUrls->elaborate`, utmUrl)
    // dai nuovi dati elabora i nuovi url
    const newUrls = await elaborateUrls(urls, urlResult, utmUrl, newUtm)

    console.log(`UpdateUrls->elaborateUrls`, newUrls)
    // aggiorna i valori nello store
    const updateValue = !isDescription ? { text: newText } : { description: newText }
    updateActive({ urls: newUrls, ...updateValue })
  }

  const handleConfirm = async () => {
    const newUtm: IUtm = {
      utm_source: utmValues.utm_source,
      utm_medium: utmValues.utm_medium,
      utm_campaign: utmValues.utm_campaign,
      utm_term: utmValues.utm_term,
      utm_content: utmValues.utm_content,
    }

    if (firstComment === undefined) {
      if ('_id' in post && post._id === 'GLOBAL') updateUrls(post.urls, newUtm)
      if ('variantId' in post) updateUrls(post.data.urls, newUtm)
    }

    if (firstComment === true) {
      const currentText = post.data.firstComment.text
      const { utmUrl, urlResult, newText } = await elaborate(currentText, newUtm)
      // dai nuovi dati elabora i nuovi url
      const newUrls = await elaborateUrls(post.data.firstComment?.urls, urlResult, utmUrl, newUtm)
      // aggiorna i valori nello store
      updatePost(post.variantId, { firstComment: { ...post.data.firstComment, text: newText, urls: newUrls } })
    }

    AppStore.closeModal('utm-modal')
  }

  /* ------------------- USEEFFECT  */
  useEffect(() => {
    const extractedUtms = extractUtmFromUrl(visibleLink.originalUrl)
    const utmValuesFromLocalStorage = extractedUtms
      ? extractedUtms
      : localStorage.getItem('utmValues')
        ? JSON.parse(localStorage.getItem('utmValues') || '{}')
        : utmValues

    setUtmValues(utmValuesFromLocalStorage)

    const keys = Object.keys(utmValuesFromLocalStorage)
    keys.forEach((key) => {
      if (!key.startsWith('utm_')) {
        delete utmValuesFromLocalStorage[key]
      }
    })
  }, [])

  const extractUtmFromUrl = (url: string) => {
    const urlParams = new URLSearchParams(url)
    const utms = {
      utm_source: urlParams.get('utm_source') ?? null,
      utm_medium: urlParams.get('utm_medium') ?? null,
      utm_campaign: urlParams.get('utm_campaign') ?? null,
      utm_term: urlParams.get('utm_term') ?? null,
      utm_content: urlParams.get('utm_content') ?? null,
    }
    if (Object.values(utms).every((v) => v === null)) {
      return null
    }
    return utms
  }

  const helpAction = () => window.open('https://help.asters.ai/it', '_blank')

  return (
    <UtmModalContainer>
      <Title>UTM</Title>

      {/* Container cambio utm */}
      <ContainerChangeUtms>
        <ChangeUtmValue>
          Source
          <Input
            placeholder={'e.g. Twitter, Facebook'}
            value={utmValues.utm_source}
            onChange={(e) => {
              setUtmValues({ ...utmValues, utm_source: e })
              onUtmValueChanged({ ...utmValues, utm_source: e })
            }}
          />
        </ChangeUtmValue>

        <ChangeUtmValue>
          Medium
          <Input
            placeholder={'e.g. cpc, banner, email'}
            value={utmValues.utm_medium}
            onChange={(e) => {
              setUtmValues({ ...utmValues, utm_medium: e })
              onUtmValueChanged({ ...utmValues, utm_medium: e })
            }}
          />
        </ChangeUtmValue>

        <ChangeUtmValue>
          Campaign
          <Input
            placeholder={'e.g. ACME-campaign'}
            value={utmValues.utm_campaign}
            onChange={(e) => {
              setUtmValues({ ...utmValues, utm_campaign: e })
              onUtmValueChanged({ ...utmValues, utm_campaign: e })
            }}
          />
        </ChangeUtmValue>

        <ChangeUtmValue>
          Term
          <Input
            placeholder={'e.g. paid keywords'}
            value={utmValues.utm_term}
            onChange={(e) => {
              setUtmValues({ ...utmValues, utm_term: e })
              onUtmValueChanged({ ...utmValues, utm_term: e })
            }}
          />
        </ChangeUtmValue>

        <ChangeUtmValue>
          Content
          <Input
            placeholder={'e.g. text AD name'}
            value={utmValues.utm_content}
            onChange={(e) => {
              setUtmValues({ ...utmValues, utm_content: e })
              onUtmValueChanged({ ...utmValues, utm_content: e })
            }}
          />
        </ChangeUtmValue>
      </ContainerChangeUtms>

      {/* Esempio utm */}
      <CompletedLink>
        <TitleSection>Esempio di url</TitleSection>

        {newUtmUrl(visibleLink, utmValues)}
      </CompletedLink>

      {/* Confirm button */}
      <Button variant="primary" size="smaller" width={100} onClick={handleConfirm}>
        {T.singleWord.confirm}
      </Button>

      {/* Cos'è un utm btn */}
      <Button
        variant="neutral"
        size="smaller"
        onClick={() => {
          window.open('https://help.asters.ai/it/articles/9047761-utm-cosa-sono-e-come-utilizzarli', '_blank')
        }}
        style={{ position: 'absolute', bottom: '-50px', left: '50%', transform: 'translateX(-50%)' }}
      >
        {T.postPage.whatAreUtm}
      </Button>
    </UtmModalContainer>
  )
}

const UtmModalContainer = styled(Box)`
  padding: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  align-items: center;
  justify-content: center;
`

const Title = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;
`

const ContainerChangeUtms = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`

const ChangeUtmValue = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-direction: column;
  gap: 6px;
  width: 49%;
`

const CompletedLink = styled(Box)`
  color: ${() => AppStore.theme.o.blue};
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  width: 100%;
  gap: 8px;
`

const TitleSection = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
